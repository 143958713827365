import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
// import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Home/ContentServicesHome";
import HeroSection from "../components/Home/HeroVideo";
import Paletacolor from "../components/global/Paletacolor";
import BlockPrincipal from "../components/block/Block_1";
// import BlockSecondary from "../components/block/Block_4";
// import BlockAbout from "../components/block/Block_1";
import VideoPromocional from "../components/global/VideoPromocional";
import ContentServices from "../components/Services/ContentServices";
import GalleryContent from "../components/Gallery/GalleryContent";
// import BlockTen from "../components/block/Block_10";
import OtherBlock from "../components/block/Block_11";
import BlockTwelve from "../components/block/Block_12";
import { FaDownload } from "react-icons/fa";
// import SlideLogo from '../components/block/SlideLogoForm'


function Home() {
  const { rpdata } = useContext(GlobalDataContext);

  const docu = 'https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2FMP%20Concrete%20LLC%20-%20Catalogo_compressed.pdf?alt=media&token=4d5028c9-d688-49df-81f6-83e3980cde93'

  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        {/* <Modal /> */}

        <HeroSection 
          urlVideo={'https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/Green%20Bay%2C%20WI.mp4?alt=media&token=5dcd9ab3-e008-4636-a365-bf8090a28c73'}
        />

        {/* <BlockTen 
          text={rpdata?.dbHome?.[0].text}
          sloganPrincipal={true}
          listsServices
          image={rpdata?.stock?.[5]}/> */}

        <BlockPrincipal
          text={rpdata?.dbHome?.[0].text}
          image={rpdata?.gallery?.[44]}
          sloganPrincipal={true}
        />

        {/* video promocional */}
        {rpdata?.videosPromo?.[0]?.activo ? (
          <VideoPromocional
            title={rpdata?.dbSlogan?.[2]?.slogan}
            text={rpdata?.dbAbout?.[1]?.text}
            linkVideo={`${rpdata?.videosPromo?.[0].link}`}
            image={`${rpdata?.videosPromo?.[0].img}`}
            
          />
        ) : null}

        {/* <div className="justify-center items-center flex ">
        <embed src="https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2FMP%20Concrete%20LLC%20-%20Catalogo_compressed.pdf?alt=media&token=4d5028c9-d688-49df-81f6-83e3980cde93" type="video/mp4" width="60%" height="600px" />
        </div> */}

        <div className="flex justify-center gap-5 flex-wrap items-center pb-5">
          <a href={docu} target="_blank" rel="noreferrer" download={docu} className="bg2 text-white rounded flex items-center p-4 gap-3">
            <p className="text-2xl">Download Catalog</p>
            <FaDownload className="text-lg" />
          </a>
        </div>

        <Directories />

        {/* <SlideLogo
        image={rpdata?.gallery?.[39]}
        text={rpdata?.dbHome?.[2]?.text}
        /> */}

        <BlockTwelve
          image={rpdata?.stock?.[2]}
          slogan={rpdata?.dbSlogan?.[6]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
        />

        {/* <BlockSecondary
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image1={rpdata?.gallery?.[13]}
          image2={rpdata?.gallery?.[14]}
          image3={rpdata?.gallery?.[15]}
        /> */}

        <CounterUp image={rpdata?.stock?.[13]} />

        <OtherBlock
          image={rpdata?.stock?.[4]}
          text={rpdata?.dbAbout?.[0].text}
          title={rpdata?.dbSlogan?.[3].slogan}
        />



        {/* <BlockAbout
          title={'a little about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.gallery?.[3]}
          listsAbout={true}
        /> */}

        {/* our reviews */}
        {rpdata?.reviews?.isHomeOnly === true ? (
          <div className="w-4/5 mx-auto py-[100px]">
            <h1 className="pb-10 text-center">
              {rpdata?.labels?.general?.titleReviews}
            </h1>
            <div className={`${rpdata?.reviews?.links?.[0]}`}></div>
          </div>
        ) : null}

        {/* Servicios del home */}
        {rpdata?.simpleWidgets?.[3]?.activo ? (
          <div className="pt-20">
            <ContentServices />
          </div>
        ) : (
          <ServicesHome />
        )}

        {/* Gallery cuando es OnePages */}

        {/* Paleta de Colores */}
        {rpdata?.simpleWidgets?.[3]?.activo ? (
          <div className="pt-10 pb-28">
            <h2 className="text-center pb-[50px]">
              {rpdata?.labels?.general?.titleGallery}
            </h2>
            <GalleryContent
              galleryImages={
                rpdata?.gallery?.length > 0 ? rpdata?.gallery : rpdata?.stock
              }
            />
          </div>
        ) : null}
        {rpdata?.brandingExtra?.[0]?.activo ? <Paletacolor /> : null}
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.stock?.[8]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>

        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
